import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ApplicationDetail = () => {
  const { id } = useParams();
  const [application, setApplication] = useState(null);

  useEffect(() => {
    // Fetch application data by ID (from an API or mock data)
    const appData = { id: 1, name: "John Doe", date: "2024-12-05", status: "Pending", projectTitle: "Research on AI", description: "Research on AI impact." };
    setApplication(appData);
  }, [id]);

  if (!application) return <div>Loading...</div>;

  return (
    <div>
      <h2>Application Details</h2>
      <p><strong>Name:</strong> {application.name}</p>
      <p><strong>Project Title:</strong> {application.projectTitle}</p>
      <p><strong>Description:</strong> {application.description}</p>
      <p><strong>Status:</strong> {application.status}</p>
      <p><strong>Submitted on:</strong> {application.date}</p>
    </div>
  );
};

export default ApplicationDetail;
