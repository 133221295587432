import React from 'react';
import UserBreadcrumb from '../Breadcrumb/UserBreadcrumbs';
import ResearchDetailsMain from './ResearchDetailsMain';
import ScrollToTop from '../ScrollTop';

const ResearchDetails = () => {
    return (
        <div className="react-wrapper">
            <div className="react-wrapper-inner">
                <UserBreadcrumb pageTitle="Research Details" />
                <ResearchDetailsMain />
                <ScrollToTop />
            </div>
        </div>
    );
};

export default ResearchDetails;
