import React, { useState } from 'react';

const EnrollmentForm = () => {
  const [enrollmentData, setEnrollmentData] = useState({
    program: '',
    academicBackground: '',
    motivation: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEnrollmentData({ ...enrollmentData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to save enrollment data (could be local storage, backend API, etc.)
    alert('Enrollment Submitted');
  };

  return (
    <div>
      <h2>Enrollment Form</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Program:
          <select name="program" value={enrollmentData.program} onChange={handleChange} required>
            <option value="">Select Program</option>
            <option value="Research">Research Program</option>
            <option value="Data Science">Data Science Program</option>
            <option value="AI">Artificial Intelligence Program</option>
          </select>
        </label>
        <label>
          Academic Background:
          <textarea name="academicBackground" value={enrollmentData.academicBackground} onChange={handleChange} required />
        </label>
        <label>
          Motivation for Enrollment:
          <textarea name="motivation" value={enrollmentData.motivation} onChange={handleChange} required />
        </label>
        <button type="submit">Submit Enrollment</button>
      </form>
    </div>
  );
};

export default EnrollmentForm;
