import React from 'react';
import { Link } from 'react-router-dom';

const UserHome = () => {
  return (
    <div>
      <h2>Welcome to the Research Grant Dashboard</h2>
      <p>Navigate through the sections below:</p>
      <ul>
        <li><Link to="/userprofile">Update Profile</Link></li>
        <li><Link to="/enrollment">Enrollment Form</Link></li>
        <li><Link to="/userdashboard/userapplicationdetail">Submit a Research Grant Application</Link></li>
      </ul>
    </div>
  );
};

export default UserHome;
