import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from "axios";


const ResearchDetailsMain = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    const [institutionNames, setInstitutionNames] = useState([]);
    const [coApplicantsTitle, setCoApplicantsTitle] = useState([]);

    useEffect(() => {
      const fetchTitle = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_URL}/api/alltitles`
          );
          const names = response.data.map((item) => item.title);
          setCoApplicantsTitle(names);
        } catch (error) {
          console.error("Error fetching titles:", error);
        }
      };
  
      fetchTitle();
    }, []);



    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth <= 600);
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);


       // Fetch institution names
       useEffect(() => {
        const fetchInstitutionNames = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/api/allschools`);
                const names = response.data.map(item => item.inst);
                setInstitutionNames(names);
            } catch (error) {
                console.error('Error fetching institution names:', error);
            }
        };

        fetchInstitutionNames();
    }, []);


    const [formData, setFormData] = useState({
        researchTitle: '',
        abstract: '',
        researchProposal: null,
        costing: null,
        hasCoApplicant: 'no',  // Track if the user has a co-applicant
        coApplicants: [], // Array to store co-applicant data
    });

    const navigate = useNavigate();

    const [errors, setErrors] = useState({
        researchTitle: '',
        abstract: '',
        researchProposal: '',
        costing: '',
        coApplicants: [], // Array to track co-applicant validation errors
    });

    // Handle file change for Research Proposal
    const handleFileChangeProposal = (e) => {
        const file = e.target.files[0];
        if (file && file.type === 'application/pdf') {
            setFormData({
                ...formData,
                researchProposal: file
            });
            setErrors({
                ...errors,
                researchProposal: ''
            });
        } else {
            setErrors({
                ...errors,
                researchProposal: 'Please upload a valid PDF file'
            });
        }
    };

    // Handle file change for Costing
    const handleFileChangeCosting = (e) => {
        const file = e.target.files[0];
        if (file && file.type === 'application/pdf') {
            setFormData({
                ...formData,
                costing: file
            });
            setErrors({
                ...errors,
                costing: ''
            });
        } else {
            setErrors({
                ...errors,
                costing: 'Please upload a valid PDF file'
            });
        }
    };

    // Handle file change for Co-Applicant CV
    const handleFileChangeCoApplicantCV = (index, e) => {
        const file = e.target.files[0];
        const updatedCoApplicants = [...formData.coApplicants];
        if (file && file.type === 'application/pdf') {
            updatedCoApplicants[index].cv = file;
            setFormData({
                ...formData,
                coApplicants: updatedCoApplicants
            });
            setErrors({
                ...errors,
                coApplicants: errors.coApplicants.map((err, idx) => (idx === index ? '' : err))
            });
        } else {
            setErrors({
                ...errors,
                coApplicants: errors.coApplicants.map((err, idx) => (idx === index ? 'Please upload a valid PDF file' : err))
            });
        }
    };

    // Handle input changes for Co-Applicant fields
    const handleCoApplicantInputChange = (index, e) => {
        const { name, value } = e.target;
        const updatedCoApplicants = [...formData.coApplicants];
        updatedCoApplicants[index][name] = value;
        setFormData({
            ...formData,
            coApplicants: updatedCoApplicants
        });
    };

 
    // Add a new Co-Applicant
    const addCoApplicant = () => {
        if (formData.coApplicants.length < 2) {
            setFormData({
                ...formData,
                coApplicants: [...formData.coApplicants, { coApplicantsTitle: '', name: '', email: '', institutionName: '', cv: null }]
            });
        }
    };

    // Remove a Co-Applicant
    const removeCoApplicant = (index) => {
        const updatedCoApplicants = formData.coApplicants.filter((_, idx) => idx !== index);
        setFormData({
            ...formData,
            coApplicants: updatedCoApplicants
        });
    };

    // Handle text input changes
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value,
      });
  };
  

    // Validate form
    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        // Validate Research Title
        if (!formData.researchTitle) {
            formErrors.researchTitle = 'Research Title is required';
            isValid = false;
        }

        // Validate Abstract
        if (!formData.abstract) {
            formErrors.abstract = 'Abstract is required';
            isValid = false;
        }

        // Validate Research Proposal (must be a file)
        if (!formData.researchProposal) {
            formErrors.researchProposal = 'Research Proposal is required';
            isValid = false;
        }

        // Validate Costing (must be a file)
        if (!formData.costing) {
            formErrors.costing = 'Costing is required';
            isValid = false;
        }

        // Validate Co-Applicants (if any)
        if (formData.coApplicants.length > 0) {
            const coApplicantErrors = formData.coApplicants.map((coApplicant, index) => {
                const coApplicantError = {};

                if (!coApplicant.coApplicantsTitle) {
                    coApplicantError.coApplicantsTitle = 'Co-Applicant Title is required';
                    isValid = false;
                }
                if (!coApplicant.name) {
                    coApplicantError.name = 'Co-Applicant Name is required';
                    isValid = false;
                }
                if (!coApplicant.email) {
                    coApplicantError.email = 'Co-Applicant Email is required';
                    isValid = false;
                }
                if (!coApplicant.institutionName) {
                    coApplicantError.institutionName = 'Co-Applicant Institution is required';
                    isValid = false;
                }
                if (!coApplicant.cv) {
                    coApplicantError.cv = 'Co-Applicant CV is required';
                    isValid = false;
                }

                return coApplicantError;
            });

            formErrors.coApplicants = coApplicantErrors;
        }

        setErrors(formErrors);
        return isValid;
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate before submitting
        if (validateForm()) {
            console.log('Form submitted successfully');
            navigate('/userdashboard/institutiondetails');
        }
    };

    const handleLogout = () => {
      localStorage.removeItem('authToken');
      window.location.href = '/login'; // Redirect to login page
  };
  

    // Inline CSS styles
    const styles = {
        input: {
          width: "100%",
          padding: "10px",
          marginTop: "5px",
          borderRadius: "4px",
          border: "1px solid #ccc",
        },
        button: {
          width: "48%",
          padding: "10px",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "16px",
          marginTop: "10px",
        },
        prevButton: {
          backgroundColor: "#ec3237",
          color: "#fff",
          border: "none",
        },
        nextButton: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          border: "none",
        },
        formRow: {
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        },
        formColumn: {
          width: "48%",
          marginRight: "2%",
        },
        formColumnLast: {
          width: "48%",
          marginRight: "0",
        },
        error: {
          color: "red",
          fontSize: "12px",
          marginTop: "5px",
        },
        buttonContainer: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        },
        textCenter: {
          fontSize: "18px",
          fontWeight: "bold",
        },
      };

  // Inline styles2
  const styles2 = {
    dashboardContainer: {
      display: "flex",
      minHeight: "100vh",
    },
    sideMenuContainer: {
      flex: 1,
      padding: "20px",
      backgroundColor: "#f9f9f9",
      borderRight: "1px solid #ddd",
    },
    contentContainer: {
      flex: 3,
      padding: "20px",
      backgroundColor: "#fff",
    },
    sideMenu: {
      marginBottom: "20px",
    },
    heading: {
      marginBottom: "10px",
      color: "#333",
    },
    status: {
      fontSize: "14px",
      marginBottom: "20px",
    },
    statusOpen: {
      color: "green",
      fontWeight: "bold",
    },
    menuButton: {
      backgroundColor: "#ec3237",
      color: "white",
      border: "none",
      borderRadius: "5px",
      width: "80%",
      marginBottom: "10px",
      padding: "10px",
      cursor: "pointer",
    },
    applicationList: {
      marginBottom: "20px",
    },
    applicationListItem: {
      marginBottom: "10px",
    },
    applicationLink: {
      textDecoration: "none",
      color: "#0066cc",
    },
    applicationLinkHover: {
      textDecoration: "underline",
    },
    primaryButton: {
      backgroundColor: "#1db540",
      color: "white",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      marginTop: "20px",
      cursor: "pointer",
    },
  };

  const SideMenu = () => {
    const handleMenuClick = (path) => {
      // Allow navigation to previous steps
      if (path === "/userdashboard" || path === "/userdashboard/personaldetails" || path === "/userdashboard/researchdetails") {
        navigate(path);
      } else {
        // Check if the form is valid before navigating to the next step
        if (validateForm()) {
          navigate(path);
        } else {
          // alert("Please complete the current form before proceeding!");
        }
      }
    };

    return (
        <div style={styles2.sideMenuContainer}>
          <h2 style={styles2.heading}>Get Started</h2>
          <p style={styles2.status}>
            <strong>
              Application Status: <span style={styles.statusOpen}>Open</span>
            </strong>
          </p>
          {[
            { text: "Home", path: "/userdashboard" },
            { text: "Personal Details", path: "/userdashboard/personaldetails" },
            { text: "Research Details", path: "/userdashboard/researchdetails" },
            { text: "Institution Details", path: "/userdashboard/institutiondetails" },
          ].map((menu, index) => (
            <button
              key={index}
              style={styles2.menuButton}
              onClick={() => handleMenuClick(menu.path)}
            >
              {menu.text}
            </button>
          ))}
          <button
                  style={styles2.menuButton}
                  onClick={() => handleLogout()}
                >
                  Log Out
                </button>
        </div>
      );
    };
  
 
  
    return (
      <div style={styles2.dashboardContainer}>
        {!isMobile && <SideMenu />}
        <div style={styles2.contentContainer}>
        <div style={styles2.applicationList}>
        <form onSubmit={handleSubmit}>
                          <div className="login-top">
                            <h3>Research Title</h3>
                          </div>
  
                          {/* Research Title */}
                          <div className="form-group">
                            <label>Research Title</label>
                            <input
                              placeholder="Research Title"
                              type="text"
                              id="researchTitle"
                              name="researchTitle"
                              value={formData.researchTitle}
                              onChange={handleInputChange}
                              style={styles.input}
                            />
                            {errors.researchTitle && (
                              <span className="error" style={styles.error}>
                                {errors.researchTitle}
                              </span>
                            )}
                          </div>
  
                          {/* Abstract */}
                          <div className="form-group">
                            <label>Abstract</label>
                            <input
                              placeholder="Abstract"
                              type="text"
                              id="abstract"
                              name="abstract"
                              value={formData.abstract}
                              onChange={handleInputChange}
                              style={styles.input}
                            />
                            {errors.abstract && (
                              <span className="error" style={styles.error}>
                                {errors.abstract}
                              </span>
                            )}
                          </div>
  
                          {/* Research Proposal Upload */}
                          <div className="form-group">
                            <label>Upload Research Proposal</label>
                            <input
                              type="file"
                              id="researchProposal"
                              name="researchProposal"
                              accept="application/pdf"
                              onChange={handleFileChangeProposal}
                              style={styles.input}
                            />
                            {errors.researchProposal && (
                              <span className="error" style={styles.error}>
                                {errors.researchProposal}
                              </span>
                            )}
                          </div>
  
                          {/* Costing Upload */}
                          <div className="form-group">
                            <label>Upload Costing</label>
                            <input
                              type="file"
                              id="costing"
                              name="costing"
                              accept="application/pdf"
                              onChange={handleFileChangeCosting}
                              style={styles.input}
                            />
                            {errors.costing && (
                              <span className="error" style={styles.error}>
                                {errors.costing}
                              </span>
                            )}
                          </div>
  
                          {/* Do you have a Co-applicant? */}
                          <div className="form-group">
                                      <label>Do you have a Co-applicant?</label>
                                      <select
                                          name="hasCoApplicant"
                                          value={formData.hasCoApplicant}
                                          onChange={handleInputChange}
                                          style={styles.input}
                                      >
                                          <option value="no">No</option>
                                          <option value="yes">Yes</option>
                                      </select>
                                  </div>
  
                                  {/* Co-applicant Details (if applicable) */}
                                  {formData.hasCoApplicant === 'yes' && (
                                      <div>
                                          <h4>Add Co-Applicant(s)</h4>
  
                                          {/* Loop through co-applicants */}
                                          {formData.coApplicants.map((coApplicant, index) => (
                                              <div key={index} className="co-applicant">
                                                  <div className="form-row" style={styles.formRow}>
                                                      <div className="form-column" style={styles.formColumn}>
                                                          <label>Co-Applicant Title</label>
                                                          <select
                                              name="coApplicantsTitle"
                                              id='coApplicantsTitle'
                                              value={coApplicant.coApplicantsTitle}
                                              onChange={(e) => handleCoApplicantInputChange(index, e)}
                                              style={styles.select}
                                          >
                                              <option value="">Select Title</option>
              {coApplicantsTitle.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
                 ))}
                                          </select>
                                                          {errors.coApplicants[index]?.coApplicantsTitle && <span style={styles.error}>{errors.coApplicants[index].coApplicantsTitle}</span>}
                                                      </div>
  
                                                      
                                                  </div>
  
                                                  <div className="form-row" style={styles.formRow}>
                                                  <div className="form-column" style={styles.formColumnLast}>
                                                          <label>Co-Applicant Name</label>
                                                          <input
                                                              type="text"
                                                              name="name"
                                                              value={coApplicant.name}
                                                              onChange={(e) => handleCoApplicantInputChange(index, e)}
                                                              style={styles.input}
                                                          />
                                                          {errors.coApplicants[index]?.name && <span style={styles.error}>{errors.coApplicants[index].name}</span>}
                                                      </div>
                                                      <div className="form-column" style={styles.formColumn}>
                                                          <label>Co-Applicant Email</label>
                                                          <input
                                                              type="email"
                                                              name="email"
                                                              value={coApplicant.email}
                                                              onChange={(e) => handleCoApplicantInputChange(index, e)}
                                                              style={styles.input}
                                                          />
                                                          {errors.coApplicants[index]?.email && <span style={styles.error}>{errors.coApplicants[index].email}</span>}
                                                      </div>
  
                                                  </div>
  
                                              
                                                  <div className="form-row" style={styles.formRow}>
                                                  <div className="form-column" style={styles.formColumnLast}>
                                                          <label>Co-Applicant Institution</label>
                                                          <select
                                        id="institutionName"
                                        name="institutionName"
                                        value={coApplicant.institutionName}
                                        onChange={(e) => handleCoApplicantInputChange(index, e)}
                                        style={{ width: '100%', padding: '10px', borderRadius: '4px' }}
                                    >
                                        <option value="" >Select Institution</option>
                                        {institutionNames.map((name, index) => (
                                            <option key={index} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.coApplicants[index]?.institutionName && <span style={styles.error}>{errors.coApplicants[index].institutionName}</span>}

                                                      </div>
                                                      <div className="form-column" style={styles.formColumn}>
                                                          <label>Upload Co-Applicant CV</label>
                                                          <input
                                                              type="file"
                                                              accept="application/pdf"
                                                              onChange={(e) => handleFileChangeCoApplicantCV(index, e)}
                                                              style={styles.input}
                                                          />
                                                          {errors.coApplicants[index]?.cv && <span style={styles.error}>{errors.coApplicants[index].cv}</span>}
                                                      </div>
                                                  </div>
  
                                                  {/* Remove Co-Applicant Button */}
                                                  <button
                                                      type="button"
                                                      onClick={() => removeCoApplicant(index)}
                                                      style={styles.button}
                                                  >
                                                      Remove Co-Applicant
                                                  </button>
                                              </div>
                                          ))}
  
                                          {/* Add Co-Applicant Button */}
                                          {formData.coApplicants.length < 2 && (
                                              <button
                                                  type="button"
                                                  onClick={addCoApplicant}
                                                  style={styles.button}
                                              >
                                                  Add Co-Applicant
                                              </button>
                                          )}
                                      </div>
                                  )}
  
                          {/* Button Container */}
                          <div style={styles.buttonContainer}>
                            <button
                              type="button"
                              style={{ ...styles.button, ...styles.prevButton }}
                              onClick={() =>
                                navigate("/userdashboard/personaldetails")
                              }
                            >
                              Prev
                            </button>
  
                            <div style={styles.textCenter}>2/3</div>
  
                            <button
                              type="submit"
                              style={{ ...styles.button, ...styles.nextButton }}
                            >
                              Next
                            </button>
                          </div>
                        </form>
      </div>
        </div>
      </div>
    );
  };
  
  export default ResearchDetailsMain;
