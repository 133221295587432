import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const InstitutionDetailsMain = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 600);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    const [formData, setFormData] = useState({
        institutionName: '',
        institutionDept: '',
        cv: null,
    });

    const [institutionNames, setInstitutionNames] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const navigate = useNavigate();

    const [errors, setErrors] = useState({
        institutionName: '',
        institutionDept: '',
        cv: '',
    });

    // Fetch institution names
    useEffect(() => {
        const fetchInstitutionNames = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}/api/allschools`);
                const names = response.data.map(item => item.inst);
                setInstitutionNames(names);
            } catch (error) {
                console.error('Error fetching institution names:', error);
            }
        };

        fetchInstitutionNames();
    }, []);

    // Fetch faculties whenever an institution is selected
    useEffect(() => {
            const fetchFaculties = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_URL}/api/allfaculties`);
                    const facultyNames = response.data.map(item => item.faculty); // Adjust based on API response
                    setFaculties(facultyNames);
                } catch (error) {
                    console.error('Error fetching faculties:', error);
                }
            };

            fetchFaculties();
        }, []);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type.startsWith('application/pdf')) {
                setFormData({ ...formData, cv: file });
                setErrors({ ...errors, cv: '' });
            } else {
                setErrors({ ...errors, cv: 'Please upload a valid PDF file' });
            }
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!formData.institutionName) {
            formErrors.institutionName = 'Institution Name is required';
            isValid = false;
        }

        if (!formData.institutionDept) {
            formErrors.institutionDept = 'Department is required';
            isValid = false;
        }

        if (!formData.cv) {
            formErrors.cv = 'CV is required';
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Form submitted successfully');
            navigate('/userdashboard');
        }
    };

    const handleLogout = () => {
      localStorage.removeItem('authToken');
      window.location.href = '/login'; // Redirect to login page
  };
  

    // Inline CSS styles
    const styles = {
        input: {
          width: "100%",
          padding: "10px",
          marginTop: "5px",
          borderRadius: "4px",
          border: "1px solid #ccc",
        },
        button: {
          width: "48%",
          padding: "10px",
          borderRadius: "4px",
          cursor: "pointer",
          fontSize: "16px",
          marginTop: "10px",
        },
        prevButton: {
          backgroundColor: "#ec3237",
          color: "#fff",
          border: "none",
        },
        nextButton: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          border: "none",
        },
        formRow: {
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        },
        formColumn: {
          width: "48%",
          marginRight: "2%",
        },
        formColumnLast: {
          width: "48%",
          marginRight: "0",
        },
        error: {
          color: "red",
          fontSize: "12px",
          marginTop: "5px",
        },
        buttonContainer: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "20px",
        },
        textCenter: {
          fontSize: "18px",
          fontWeight: "bold",
        },
      };
    
      // Inline styles2
      const styles2 = {
        dashboardContainer: {
          display: "flex",
          minHeight: "100vh",
        },
        sideMenuContainer: {
          flex: 1,
          padding: "20px",
          backgroundColor: "#f9f9f9",
          borderRight: "1px solid #ddd",
        },
        contentContainer: {
          flex: 3,
          padding: "20px",
          backgroundColor: "#fff",
        },
        sideMenu: {
          marginBottom: "20px",
        },
        heading: {
          marginBottom: "10px",
          color: "#333",
        },
        status: {
          fontSize: "14px",
          marginBottom: "20px",
        },
        statusOpen: {
          color: "green",
          fontWeight: "bold",
        },
        menuButton: {
          backgroundColor: "#ec3237",
          color: "white",
          border: "none",
          borderRadius: "5px",
          width: "80%",
          marginBottom: "10px",
          padding: "10px",
          cursor: "pointer",
        },
        applicationList: {
          marginBottom: "20px",
        },
        applicationListItem: {
          marginBottom: "10px",
        },
        applicationLink: {
          textDecoration: "none",
          color: "#0066cc",
        },
        applicationLinkHover: {
          textDecoration: "underline",
        },
        primaryButton: {
          backgroundColor: "#1db540",
          color: "white",
          padding: "10px 20px",
          border: "none",
          borderRadius: "5px",
          marginTop: "20px",
          cursor: "pointer",
        },
      };

      const SideMenu = () => {
        const handleMenuClick = (path) => {
          // Allow navigation to previous steps
          if (path === "/userdashboard" || path === "/userdashboard/personaldetails" || path === "/userdashboard/researchdetails" || path === "/userdashboard/institutiondetails") {
            navigate(path);
          } else {
            // Check if the form is valid before navigating to the next step
            if (validateForm()) {
              navigate(path);
            } else {
              // alert("Please complete the current form before proceeding!");
            }
          }
        };

        return (
            <div style={styles2.sideMenuContainer}>
              <h2 style={styles2.heading}>Get Started</h2>
              <p style={styles2.status}>
                <strong>
                  Application Status: <span style={styles2.statusOpen}>Open</span>
                </strong>
              </p>
              {[
                { text: "Home", path: "/userdashboard" },
                { text: "Personal Details", path: "/userdashboard/personaldetails" },
                { text: "Research Details", path: "/userdashboard/researchdetails" },
                { text: "Institution Details", path: "/userdashboard/institutiondetails" },
              ].map((menu, index) => (
                <button
                  key={index}
                  style={styles2.menuButton}
                  onClick={() => handleMenuClick(menu.path)}
                >
                  {menu.text}
                </button>
              ))}
              <button
                  style={styles2.menuButton}
                  onClick={() => handleLogout()}
                >
                  Log Out
                </button>
            </div>
          );
        };
      
        const ApplicationList = () => (
          <div style={styles2.applicationList}>
            <form onSubmit={handleSubmit}>
              <div className="login-top">
                <h3>Institution Details</h3>
              </div>
      
              {/* Institution Name */}
              <div className="form-group">
                <label>Institution Name</label>
                <select
                                        id="institutionName"
                                        name="institutionName"
                                        value={formData.institutionName}
                                        onChange={handleInputChange}
                                        style={{ width: '100%', padding: '10px', borderRadius: '4px' }}
                                    >
                                        <option value="" >Select Institution</option>
                                        {institutionNames.map((name, index) => (
                                            <option key={index} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                {errors.institutionName && (
                  <span className="error" style={styles.error}>
                    {errors.institutionName}
                  </span>
                )}
              </div>
      
              {/* Department */}
              <div className="form-group">
                <label>Department</label>
                <select
                                        id="institutionDept"
                                        name="institutionDept"
                                        value={formData.institutionDept}
                                        onChange={handleInputChange}
                                        style={{ width: '100%', padding: '10px', 
                                        borderRadius: '4px' }}
                                    >
                                        <option value="">Select Faculty</option>
                                        {faculties.map((faculty, index) => (
                                            <option key={index} value={faculty}>
                                                {faculty}
                                            </option>
                                        ))}
                                    </select>
                {errors.institutionDept && (
                  <span className="error" style={styles.error}>
                    {errors.institutionDept}
                  </span>
                )}
              </div>
      
              {/* Upload CV */}
              <div className="form-group">
                <label>Upload Academic CV</label>
                <input
                  type="file"
                  id="cv"
                  name="cv"
                  accept="application/pdf"
                  onChange={handleFileChange}
                  style={styles.input}
                />
                {errors.cv && (
                  <span className="error" style={styles.error}>
                    {errors.cv}
                  </span>
                )}
              </div>
      
              {/* Button Container */}
              <div style={styles.buttonContainer}>
                <button
                  type="button"
                  style={{ ...styles.button, ...styles.prevButton }}
                  onClick={() => navigate("/userdashboard/researchdetails")}
                >
                  Prev
                </button>
      
                <div style={styles.textCenter}>3/3</div>
      
                <button
                  type="submit"
                  style={{ ...styles.button, ...styles.nextButton }}
                >
                  Next
                </button>
              </div>
            </form>
          </div>
        );
      
        return (
          <div style={styles2.dashboardContainer}>
            {!isMobile && <SideMenu />}
            <div style={styles2.contentContainer}>
              <ApplicationList />
            </div>
          </div>
        );
      };
      
      export default InstitutionDetailsMain;

//     return (
//         <div className="react-login-page react-signup-page pt---120 pb---120">
//             <div className="container">
//                 <div className="row">
//                     <div className="col-lg-12">
//                         <div className="login-right-form">
//                             <form onSubmit={handleSubmit}>
//                                 <div className="login-top">
//                                     <h3>Institution Details</h3>
//                                 </div>

//                                 {/* Institution Name */}
//                                 <div className="form-group">
//                                     <label>Institution Name</label>
                                    // <select
                                    //     id="institutionName"
                                    //     name="institutionName"
                                    //     value={formData.institutionName}
                                    //     onChange={handleInputChange}
                                    //     style={{ width: '100%', padding: '10px', borderRadius: '4px' }}
                                    // >
                                    //     <option value="" >Select Institution</option>
                                    //     {institutionNames.map((name, index) => (
                                    //         <option key={index} value={name}>
                                    //             {name}
                                    //         </option>
                                    //     ))}
                                    // </select>
//                                     {errors.institutionName && (
//                                         <span style={styles.error}>{errors.institutionName}</span>
//                                     )}
//                                 </div>

//                                 {/* Faculty/Department */}
//                                 <div className="form-group">
//                                     <label>Faculty/Department</label>
                                    // <select
                                    //     id="institutionDept"
                                    //     name="institutionDept"
                                    //     value={formData.institutionDept}
                                    //     onChange={handleInputChange}
                                    //     style={{ width: '100%', padding: '10px', 
                                    //     borderRadius: '4px' }}
                                    // >
                                    //     <option value="">Select Faculty</option>
                                    //     {faculties.map((faculty, index) => (
                                    //         <option key={index} value={faculty}>
                                    //             {faculty}
                                    //         </option>
                                    //     ))}
                                    // </select>
//                                     {errors.institutionDept && (
//                                         <span className='error 'style={styles.error}>{errors.institutionDept}</span>
//                                     )}
//                                 </div>

//                                 {/* Upload CV */}
//                                 <div className="form-group">
//                                     <label>Upload Academic CV</label>
//                                     <input
//                                         type="file"
//                                         id="cv"
//                                         name="cv"
//                                         accept="application/pdf"
//                                         onChange={handleFileChange}
//                                         style={{ width: '100%', padding: '10px', borderRadius: '4px' }}
//                                     />
//                                     {errors.cv && <span className="error" style={styles.error}>{errors.cv}</span>}
//                                 </div>

//                                 {/* Button Container */}
//                                 <div style={styles.buttonContainer}>
//                                     <button
//                                         type="button"
//                                         style={{ ...styles.button, ...styles.prevButton }}
//                                         onClick={() => navigate('/userdashboard/researchdetails')}
//                                     >
//                                         Prev
//                                     </button>

//                                     <div style={styles.textCenter}>
//                                         3/3
//                                     </div>

//                                     <button
//                                         type="submit"
//                                         style={{ ...styles.button, ...styles.nextButton }}
//                                     >
//                                         Submit
//                                     </button>
//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default InstitutionDetailsMain;
