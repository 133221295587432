import React from 'react';
import Slider from "react-slick";

import SectionTitle from '../../components/SectionTitle'
import SingleTestimonial from '../../components/Testimonial/SingleTestimonial';
import RecipientImg1 from '../../assets/images/recipient/1.jpg';
import RecipientImg2 from '../../assets/images/recipient/2.jpg'; // Example additional import
import RecipientImg3 from '../../assets/images/recipient/3.jpg'; // Example additional import
import RecipientImg4 from '../../assets/images/recipient/4.png'; // Example additional import
import RecipientImg5 from '../../assets/images/recipient/5.jpg'; // Example additional import
import RecipientImg6 from '../../assets/images/recipient/6.jpg'; // Example additional import
import RecipientImg7 from '../../assets/images/recipient/7.jpg'; // Example additional import
import RecipientImg8 from '../../assets/images/recipient/8.jpg'; // Example additional import
import RecipientImg9 from '../../assets/images/recipient/9.png'; // Example additional import

import testiImg from '../../assets/images/testimonial/testimonial.png';
import comaImg from '../../assets/images/testimonial/coma.png';

const Testimonial = () => {

    const testimonialSettings = {
        dots: false,
        arrows: true,
        infinite: true,
        centerMode: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 1,
                }
            },
            {

                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                }
            }
        ]
    };

    return (
        <>
            <div className="react-clients react-clientso home-testimonial pt---90 pb---120">
                <div className="container">
                    <SectionTitle Title="Past Recipient" />
                    <div className="client-slider wow animate__fadeInUp" data-wow-duration="0.3s">
                        <Slider {...testimonialSettings}>
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={RecipientImg1 }
                                Title="Dr. Olasunmbo Afolayan"
                                Designation="Unversity of Lagos"
                                Desc="Nulla porttitor accumsan tincidunt. vamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Quisque velit nisi, pretium ut lacinia in."
                                ratingCount='4.9'
                                comaImg={comaImg}
                                amount="NGN100,000"
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={RecipientImg2 }
                                Title="Dr. Somotchukwu Evurani"
                                Designation="University of Nigerian, Nsukka"
                                Desc="Nulla porttitor accumsan tincidunt. vamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Quisque velit nisi, pretium ut lacinia in."
                                ratingCount='4.9'
                                comaImg={comaImg}
                                amount="NGN100,000"
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={RecipientImg3 }
                                Title="Dr. Chika Okonkwo"
                                Designation="Nnamdi Azikiwe University, Awka"
                                Desc="Nulla porttitor accumsan tincidunt. vamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Quisque velit nisi, pretium ut lacinia in."
                                ratingCount='4.9'
                                comaImg={comaImg}
                                amount="NGN100,000"
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={RecipientImg8 }
                                Title="Dr. Dr. Okunola Alabi"
                                Designation="Federal University of Technology Akure"
                                Desc="Nulla porttitor accumsan tincidunt. vamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Quisque velit nisi, pretium ut lacinia in."
                                ratingCount='4.9'
                                comaImg={comaImg}
                                amount="NGN100,000"
                            />
                            <SingleTestimonial
                                itemClass="single-client"
                                itemImg={RecipientImg4 }
                                Title="Dr. Kayode Ileke"
                                Designation="Federal University of Technology Akure"
                                Desc="Nulla porttitor accumsan tincidunt. vamus magna justo, lacinia eget consectetur sed, convallis at tellus. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Quisque velit nisi, pretium ut lacinia in."
                                ratingCount='4.9'
                                comaImg={comaImg}
                                amount="NGN100,000"
                            />
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Testimonial