import React from 'react';
import UserBreadcrumb from '../Breadcrumb/UserBreadcrumbs';
import PersonalDetailsMain from './UserPersonalDetailsMain';
import ScrollToTop from '../ScrollTop';

const PersonalDetails = () => {
    return (
        <div className="react-wrapper">
            <div className="react-wrapper-inner">
                <UserBreadcrumb pageTitle="Personal Details" />
                <PersonalDetailsMain />
                <ScrollToTop />
            </div>
        </div>
    );
};

export default PersonalDetails;
