import React from 'react';
import { Link } from 'react-router-dom';

import aboutImg from '../../assets/images/about/ab.jpg'
import shapeImg from '../../assets/images/about/ffsd-logo.png'

const AboutPart = () => {

    return (
        <div className="about__area about__area_one p-relative pt---100 pb---120">
            <div className="container"> 
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about__image wow animate__fadeInUp" data-wow-duration="0.3s">
                            <img src={aboutImg} alt="About" />
                            <img className="react__shape__ab" src={shapeImg} alt="Shape Image" />
                        </div>
                        <div>
                            <h2  style={{paddingTop: "32px", marginBottom: "8px"}}>Get Started</h2>
                            <p style={{paddingLeft: "10px", lineHeight: "0px"}}>
                                <span style={{fontSize: "13pt"}}>
                                    <strong>
                                        <span style={{color: "#ff0000"}}>Application Status: </span>
                                       <span>Open</span>
                                       <span style={{backgroundColor: "#1db540", width: "15px", fontSize: "40px"}}> </span>
                                     </strong></span>
                                
                            </p>
                            <p style={{paddingLeft: "10px",  lineHeight: "0px"}}>
                                <span style={{fontSize: "13pt"}}>
                                    <strong>
                                        <span style={{color: "#ff0000"}}>Start Date: </span>
                                        <span>20 December, 2024</span>
                                    </strong>
                                </span>
                            </p>
                            <p style={{paddingLeft: "10px",  lineHeight: "0px"}}>
                                <span style={{fontSize: "13pt"}}>
                                    <strong>
                                        <span style={{color: "#ff0000"}}>End Date:  </span>
                                        <span>29 March, 2024</span>
                                     </strong>
                            </span>
                                
                            </p>
                            <div style={{ paddingLeft: "10px", display: 'flex', justifyContent: 'start', gap: '10px' }}>
    <Link 
        to="../../assets/pdfs/FFSD Small Research Grant 2024.pdf" 
        target="_blank" 
        rel="noopener noreferrer" 
        download 
        style={{ 
            backgroundColor: "#353535", 
            color: "white", 
            borderRadius: "5px", 
            padding: "10px 20px", 
            textDecoration: "none", 
            textAlign: "center" 
        }}
    >
        Download Application Guidiance
    </Link>
    <Link to="/signup"> 
        <button style={{ backgroundColor: "#EC3237", color: "white", borderRadius: "5px" }}> Apply Now</button>
    </Link>
</div>


                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="about__content">
                            <h2 className="about__title wow animate__fadeInUp" data-wow-duration="0.3s">Welcome to <br/> <em>FFSD Small Research Grant</em></h2>
                            <p className="about__paragraph wow animate__fadeInUp" data-wow-duration="0.5s">We invite researchers to apply for our Small Research grant aimed at fostering impactful research across a broad spectrum of academic fields. Within this program, we enthusiastically invite proposals from a wide range of research disciplines, encompassing but not limited to physical sciences, life sciences, social sciences, engineering, information technology, education, business management and humanities. While we are accepting research proposals across all these disciplines, we particularly encourage applications from research areas in business management, social sciences, and humanities. </p>
                            <p className="about__paragraph2 wow animate__fadeInUp" data-wow-duration="0.7s"> Want to know more?  <Link to="#"> Review The Application Guidiance Document </Link></p>
                            <p className="wow animate__fadeInUp" data-wow-duration="0.6s">Our aim is to cultivate a dynamic and inclusive research community, and we are eager to provide support for your compelling research projects. We eagerly anticipate the opportunity to assist and contribute to the success of your exciting endeavors.</p>
                            <ul className="wow animate__fadeInUp" data-wow-duration="0.9s">
                                {/* <li><Link to="/about" className="more-about"> Read More <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg></Link></li> */}
                                <li className="last-li">
                                    <em>Get Support</em>
                                    <Link to="mailto:researchgrants@ffsdgroup.com">researchgrants@ffsdgroup.com</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPart;