import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const ResearchGrantDashboard = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [personalDetailsCompleted, setPersonalDetailsCompleted] = useState(false); // Track personal details completion

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigate = useNavigate();

  const [applications, setApplications] = useState([
    { id: 1, name: "John Doe", date: "2024-12-05", status: "Pending" },
    { id: 2, name: "Jane Smith", date: "2024-12-06", status: "Approved" },
    { id: 3, name: "Alice Johnson", date: "2024-12-07", status: "Rejected" },
  ]);

  // Inline styles
  const styles = {
    dashboardContainer: {
      display: "flex",
      minHeight: "100vh",
    },
    sideMenuContainer: {
      flex: 1,
      padding: "20px",
      backgroundColor: "#f9f9f9",
      borderRight: "1px solid #ddd",
    },
    contentContainer: {
      flex: 3,
      padding: "20px",
      backgroundColor: "#fff",
    },
    sideMenu: {
      marginBottom: "20px",
    },
    heading: {
      marginBottom: "10px",
      color: "#333",
    },
    status: {
      fontSize: "14px",
      marginBottom: "20px",
    },
    statusOpen: {
      color: "green",
      fontWeight: "bold",
    },
    menuButton: {
      backgroundColor: "#ec3237",
      color: "white",
      border: "none",
      borderRadius: "5px",
      width: "80%",
      marginBottom: "10px",
      padding: "10px",
      cursor: "pointer",
    },
    applicationList: {
      marginBottom: "20px",
    },
    applicationListItem: {
      marginBottom: "10px",
    },
    applicationLink: {
      textDecoration: "none",
      color: "#0066cc",
    },
    applicationLinkHover: {
      textDecoration: "underline",
    },
    primaryButton: {
      backgroundColor: "#1db540",
      color: "white",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      marginTop: "20px",
      cursor: "pointer",
    },
  };

//   const handleMenuClick = (section) => {
//     if (section !== "Home" && section !== "Personal Details" && !personalDetailsCompleted) {
//       alert("Please fill out your Personal Details first.");
//     }
//   };
const handleMenuClick = (path) => {
    // Allow navigation to previous steps
    if (path === "/userdashboard" || path === "/userdashboard/personaldetails" ) {
      navigate(path);
    } else {
        alert("Please fill out your Personal Details first.");
        navigate("/userdashboard/personaldetails");
    }
    // } else {
    //   // Check if the form is valid before navigating to the next step
    //   if (validateForm()) {
    //     navigate(path);
    //   } else {
    //     // alert("Please complete the current form before proceeding!");
    //   }
    // }
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    window.location.href = '/login'; // Redirect to login page
};

  

  const SideMenu = () => (
    // <div style={styles.sideMenuContainer}>
    //   <h2 style={styles.heading}>Get Started</h2>
    //   <p style={styles.status}>
    //     <strong>
    //       Application Status: <span style={styles.statusOpen}>Open</span>
    //     </strong>
    //   </p>
    //   {[
    //     "Home",
    //     "Personal Details",
    //     "Research Details",
    //     "Institution Details",
    //   ].map((text, index) => (
    //     <Link
    //       to={
    //         text === "Home"
    //           ? "/userdashboard"
    //           : `/userdashboard/${text.toLowerCase().replace(" ", "")}`
    //       }
    //       key={index}
    //     >
    //       <button
    //         style={styles.menuButton}
    //         onClick={() => handleMenuClick(text)}
    //       >
    //         {text}
    //       </button>
    //     </Link>
    //   ))}
    //   <Link
    //       to=""
    //     >
    //       <button
    //         style={styles.menuButton}
    //         onClick={() => handleLogout()}
    //       >
    //         LOG OUT
    //       </button>
    //     </Link>
    // </div>
    <div style={styles.sideMenuContainer}>
              <h2 style={styles.heading}>Get Started</h2>
              <p style={styles.status}>
                <strong>
                  Application Status: <span style={styles.statusOpen}>Open</span>
                </strong>
              </p>
              {[
                { text: "Home", path: "/userdashboard" },
                { text: "Personal Details", path: "/userdashboard/personaldetails" },
                { text: "Research Details", path: "/userdashboard/researchdetails" },
                { text: "Institution Details", path: "/userdashboard/institutiondetails" },
              ].map((menu, index) => (
                <button
                  key={index}
                  style={styles.menuButton}
                  onClick={() => handleMenuClick(menu.path)}
                >
                  {menu.text}
                </button>
              ))}
              <button
                  style={styles.menuButton}
                  onClick={() => handleLogout()}
                >
                  Log Out
                </button>
            </div>
  );

  const ApplicationList = () => (
    <div style={styles.applicationList}>
      <h2 style={styles.heading}>Submitted Applications</h2>
      <ul style={{ padding: "0", margin: "0", listStyle: "none" }}>
        {applications.map((app) => (
          <li key={app.id} style={styles.applicationListItem}>
            <Link
              to={`/userdashboard/applicationdetails/${app.id}`}
              style={styles.applicationLink}
            >
              {app.name} - {app.status} (Submitted on: {app.date})
            </Link>
          </li>
        ))}
      </ul>
      <Link to="/userdashboard/personaldetails">
        <button style={styles.primaryButton}>Create New Application</button>
      </Link>
    </div>
  );

  return (
    <div style={styles.dashboardContainer}>
      {!isMobile && <SideMenu />}
      <div style={styles.contentContainer}>
        <ApplicationList />
      </div>
    </div>
  );
};

export default ResearchGrantDashboard;
