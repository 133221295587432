import React, { useState, useEffect } from "react";
import { Routes, Route } from 'react-router-dom';
import Preloader from "../components/Preloader";
import ProtectedRoute from './ProtectedRoute';

// Custom Components
import Home from '../pages/home';
import About from '../pages/about';
import OTP from "../pages/authentication/otp";
import UserDashboard from "../pages/dashboard";

import UserHome from '../components/Dashboard/Userhome';
import PersonalDetails from '../components/Dashboard/UserPersonalDetails';
import EnrollmentForm from '../components/Dashboard/UserEnrollmentForm';
import ApplicationForm from '../components/Dashboard/UserApplicationForm';
import ApplicationDetail from '../components/Dashboard/UserApplicationDetail';
import Login from '../pages/authentication/login';
import Signup from '../pages/authentication/signup';
import Contact from '../pages/contact';
import Error from '../pages/404';
import LoadTop from '../components/ScrollTop/LoadTop';
import InstitutionDetails from "../components/Dashboard/InstitutionDetails";
import ResearchDetails from "../components/Dashboard/ResearchDetails";

const App = () => {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []);

    return (
        <div className='App'>
            {isLoading ? <Preloader /> : ''}
            <>
                <LoadTop />
                <Routes>
                <Route path="/login" element={<Login />} />
                <Route 
                    path="/userdashboard/*" 
                    element={
                        <ProtectedRoute>
                            <UserDashboard />
                        </ProtectedRoute>
                    } 
                />
                    <Route path="/" exact element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/otp" element={<OTP />} />

                    <Route path="/userdashboard/personaldetails" element={ <ProtectedRoute> <PersonalDetails /> </ProtectedRoute>} />
                    <Route path="/userdashboard/institutiondetails" element={ <ProtectedRoute> <InstitutionDetails /> </ProtectedRoute>} />
                    <Route path="/userdashboard/researchdetails" element={ <ProtectedRoute> <ResearchDetails /> </ProtectedRoute>} />
                    <Route path="/userdashboard/enrollment" element={<EnrollmentForm />} /> {/* Updated path */}
                    <Route path="/userdashboard/apply" element={<ApplicationForm />} /> {/* Updated path */}
                    <Route path="/userdashboard/applicationdetails" element={<ProtectedRoute> <ApplicationDetail /> </ProtectedRoute>} /> {/* Updated path */}
                    <Route path="/userhome" element={<UserHome />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path='*' element={<Error />} />
                </Routes>
            </>
        </div>
    );
}

export default App;
