import React from "react";
import { useNavigate } from "react-router-dom";
import "./Popup.css"; // Assuming the styles are in Popup.css

const Popup = ({ message, type, onClose, buttonLabel, buttonRoute }) => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        if (buttonRoute) {
            navigate(buttonRoute);
        }
        onClose(); // Optionally close the popup
    };

    return (
        <div className="popup-overlay" onClick={onClose}>
            <div
                className={`popup-box ${type === "success" ? "popup-success" : "popup-error"}`}
                onClick={(e) => e.stopPropagation()} // Prevent closing on popup click
            >
                <button className="popup-close" onClick={onClose}>
                    &times;
                </button>
                <div className="popup-message">{message}</div>
                {buttonLabel && buttonRoute && (
                    <button className="popup-action-button" onClick={handleButtonClick}>
                        {buttonLabel}
                    </button>
                )}
            </div>
        </div>
    );
};

export default Popup;
