// import React, { useState } from 'react';
// import './App.css';
// import { Link } from 'react-router-dom';


// function UserDashboard() {
//     const [applications, setApplications] = useState([
//         { id: 1, name: "John Doe", date: "2024-12-05", status: "Pending" },
//         { id: 2, name: "Jane Smith", date: "2024-12-06", status: "Approved" },
//         { id: 3, name: "Alice Johnson", date: "2024-12-07", status: "Rejected" }
//     ]);

    // const handleLogout = () => {
    //     localStorage.removeItem('authToken');
    //     window.location.href = '/login'; // Redirect to login page
    // };
    
    
//     return (
//         <div className="userhome">
//             <h1>Research Grant Dashboard</h1>
//             <nav>
//                 <Link to="/userdashboard">Home</Link> | 
//                 <Link to="/userdashboard/personaldetails">Personal Details</Link> | {/* Updated link */}
//                 <Link to="/userdashboard/researchdetails">Research Details</Link> | {/* Updated link */}

//                 <Link to="/userdashboard/institutiondetails">Institution Details</Link> | {/* Updated link */}
//                 <button onClick={handleLogout}>Log out</button>

//                 {/* <Link to="/userdashboard/personaldetails">Apply for Grant</Link>  */}
//             </nav>
//             <div>
//                 <h2>Submitted Applications</h2>
//                 <ul>
//                     {applications.map((app) => (
//                         <li key={app.id}>
//                             <Link to={`/userdashboard/applicationdetails/${app.id}`}> {/* Updated link */}
//                                 {app.name} - {app.status} (Submitted on: {app.date})
//                             </Link>
//                         </li>
//                     ))}
//                 </ul>
//                 <Link to="/userdashboard/personaldetails">
//                     <button>Create New Application</button>
//                 </Link>
//             </div>
//         </div>
//     );
// }

// export default UserDashboard;



import React, { useState } from 'react';
import './App.css';
// import { Link } from 'react-router-dom';
import UserBreadcrumb from '../../components/Breadcrumb/UserBreadcrumbs';
import PersonalDetailsMain from '../../components/Dashboard/UserPersonalDetailsMain';
// import About from '../about';
import ResearchGrantDashboard from '../home/ResearchGrantDashboard';
// import ScrollToTop from '../../ScrollToTop';

function UserDashboard() {
    const [applications, setApplications] = useState([
        { id: 1, name: "John Doe", date: "2024-12-05", status: "Pending" },
        { id: 2, name: "Jane Smith", date: "2024-12-06", status: "Approved" },
        { id: 3, name: "Alice Johnson", date: "2024-12-07", status: "Rejected" }
    ]);
    
    return (
        <div className="react-wrapper">
            <div className="react-wrapper-inner">
                <UserBreadcrumb pageTitle="Research Grant Dashboard" />
                {/* <PersonalDetailsMain /> */}
                <ResearchGrantDashboard />
                
                {/* <ScrollToTop /> */}
            </div>
        </div>
    );
}

export default UserDashboard;
