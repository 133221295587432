import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Popup from "../Popup/Popup";


const PersonalDetailsMain = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isLoading, setIsLoading] = useState(false);
  const [popup, setPopup] = useState({ message: "", type: "", isVisible: false, buttonLabel: "", buttonRoute: "" });
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    otherName: "",
    lastName: "",
    phoneNumber: "",
    gender: "",
    state: "",
    confirmPassword: "",
    profilePhoto: null,
  });

  const navigate = useNavigate();

  const [errors, setErrors] = useState({
    profilePhoto: "",
    title: "",
    firstName: "",
    otherName: "",
    lastName: "",
    gender: "",
    phoneNumber: "",
    state: "",
  });

  const [title, setTitle] = useState([]);
  const [gender, setGender] = useState([]);
  const [state, setState] = useState([]);

  useEffect(() => {
    const fetchTitle = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/alltitles`
        );
        const names = response.data.map((item) => item.title);
        setTitle(names);
      } catch (error) {
        console.error("Error fetching titles:", error);
      }
    };

    fetchTitle();
  }, []);

  // Fetch faculties whenever an institution is selected
  useEffect(() => {
    const fetchGender = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/allgenders`
        );
        const gender = response.data.map((item) => item.gender); // Adjust based on API response
        setGender(gender);
      } catch (error) {
        console.error("Error fetching gender:", error);
      }
    };

    fetchGender();
  }, []);

  useEffect(() => {
    const fetchState = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/allstates`
        );
        const names = response.data.map((item) => item.state);
        setState(names);
      } catch (error) {
        console.error("Error fetching States names:", error);
      }
    };

    fetchState();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setFormData({
        ...formData,
        profilePhoto: file,
      });
      setErrors({
        ...errors,
        profilePhoto: "",
      });
    } else {
      setErrors({
        ...errors,
        profilePhoto: "Please upload a valid image file",
      });
    }
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    // Validation for each field (same as before)
    if (!formData.title) {
      formErrors.title = "Title is required";
      isValid = false;
    }

    if (!formData.firstName) {
      formErrors.firstName = "First Name is required";
      isValid = false;
    }

    if (!formData.lastName) {
      formErrors.lastName = "Last Name is required";
      isValid = false;
    }

    if (!formData.phoneNumber) {
      formErrors.phoneNumber = "Phone Number is required";
      isValid = false;
    }

    if (!formData.gender) {
      formErrors.gender = "Gender is required";
      isValid = false;
    }

    if (!formData.country) {
      formErrors.country = "Country is required";
      isValid = false;
    }

    if (!formData.state) {
      formErrors.state = "State is required";
      isValid = false;
    }

    if (!formData.profilePhoto) {
      formErrors.profilePhoto = "Profile Photo is required";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData.gender)

    if (validateForm()) {
      setIsLoading(true);
      const data = {   
        first_name: formData.firstName,           
        gender_id: formData.gender,
        phone: formData.phoneNumber,
        state: formData.state,
        
        surname: formData.lastName,
        profile_picture: formData.profilePhoto,
        other_name: formData.otherName,
       
        title: formData.title,  
    };

    console.log("this is the data", data)

    console.log(formData.gender)
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/api/user/personal`, {
          method: 'POST',
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`  },
          body: JSON.stringify(data)
      });

      const result = await response.json();

      console.log("result", result)

      if (response.ok) {
          console.log('Personal Details Submitted successful:', result);
          // Save the token to localStorage
          // localStorage.setItem('authToken', result.token);

          setPopup({
              message: "Personal Details Submitted successful!",
              type: "success",
              isVisible: true,
              buttonLabel: "Proceed to your Enter Your Institution Details",
              buttonRoute: "/userdashboard/institutiondetails",
          });
      } else {
          console.error('Submission failed:', result);
          const errorMessages = Object.entries(result)
          .map(([key, messages]) => `${key}: ${messages.join(', ')}`)
          .join('\n');
          setPopup({
              message: `Submission Failed: ${errorMessages}`,
              type: "error",
              isVisible: true,
              buttonLabel: "Retry",
              buttonRoute: "/userdashboard/personaldetails",
          });
      }
  } catch (error) {
      console.error('Error during Login:', error);
      setPopup({
          message: "An error occurred. Please try again.",
          type: "error",
          isVisible: true,
          buttonLabel: "Retry",
          buttonRoute: "/userdashboard/personaldetails",
      });
  } finally {
      setIsLoading(false);
  }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    window.location.href = "/login"; // Redirect to login page
  };

  // Inline CSS styles
  const styles2 = {
    formRow: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
      flexDirection: isMobile ? "column" : "row", // Change direction based on screen size
    },
    formColumn: {
      width: isMobile ? "100%" : "48%", // Full width for smaller screens
      marginRight: isMobile ? "0" : "2%",
    },
    formColumnLast: {
      width: isMobile ? "100%" : "48%",
      marginRight: "0",
    },
    input: {
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      borderRadius: "4px",
      border: "1px solid #ccc",
    },
    select: {
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      borderRadius: "4px",
      border: "1px solid #ccc",
    },
    error: {
      color: "red",
      fontSize: "12px",
      marginTop: "5px",
    },
  };

  // Inline styles
  const styles = {
    dashboardContainer: {
      display: "flex",
      minHeight: "100vh",
    },
    sideMenuContainer: {
      flex: 1,
      padding: "20px",
      backgroundColor: "#f9f9f9",
      borderRight: "1px solid #ddd",
    },
    contentContainer: {
      flex: 3,
      padding: "20px",
      backgroundColor: "#fff",
    },
    sideMenu: {
      marginBottom: "20px",
    },
    heading: {
      marginBottom: "10px",
      color: "#333",
    },
    status: {
      fontSize: "14px",
      marginBottom: "20px",
    },
    statusOpen: {
      color: "green",
      fontWeight: "bold",
    },
    menuButton: {
      backgroundColor: "#ec3237",
      color: "white",
      border: "none",
      borderRadius: "5px",
      width: "80%",
      marginBottom: "10px",
      padding: "10px",
      cursor: "pointer",
    },
    applicationList: {
      marginBottom: "20px",
    },
    applicationListItem: {
      marginBottom: "10px",
    },
    applicationLink: {
      textDecoration: "none",
      color: "#0066cc",
    },
    applicationLinkHover: {
      textDecoration: "underline",
    },
    primaryButton: {
      backgroundColor: "#1db540",
      color: "white",
      padding: "10px 20px",
      border: "none",
      borderRadius: "5px",
      marginTop: "20px",
      cursor: "pointer",
    },
  };

  const SideMenu = () => {
    const handleMenuClick = (path) => {
      // Allow navigation to previous steps
      if (
        path === "/userdashboard" ||
        path === "/userdashboard/personaldetails"
      ) {
        navigate(path);
      } else {
        // Check if the form is valid before navigating to the next step
        if (validateForm()) {
          navigate(path);
        } else {
          // alert("Please complete the current form before proceeding!");
        }
      }
    };

    return (
      <div style={styles.sideMenuContainer}>
        <h2 style={styles.heading}>Get Started</h2>
        <p style={styles.status}>
          <strong>
            Application Status: <span style={styles.statusOpen}>Open</span>
          </strong>
        </p>
        {[
          { text: "Home", path: "/userdashboard" },
          { text: "Personal Details", path: "/userdashboard/personaldetails" },
          { text: "Research Details", path: "/userdashboard/researchdetails" },
          {
            text: "Institution Details",
            path: "/userdashboard/institutiondetails",
          },
        ].map((menu, index) => (
          <button
            key={index}
            style={styles.menuButton}
            onClick={() => handleMenuClick(menu.path)}
          >
            {menu.text}
          </button>
        ))}
        <button style={styles.menuButton} onClick={() => handleLogout()}>
          Log Out
        </button>
      </div>
    );
  };


  return (
    <div style={styles.dashboardContainer}>
      {!isMobile && <SideMenu />}
      <div style={styles.contentContainer}>
      <div style={styles.applicationList}>
      <form onSubmit={handleSubmit}>
        <div className="login-top">
          <h3>Personal Details</h3>
        </div>

        {/* Profile Photo Field */}
        <div className="form-group">
          <label>Profile Photo</label>
          <input
            type="file"
            id="profilePhoto"
            name="profilePhoto"
            accept="image/*"
            onChange={handleFileChange}
            style={styles2.input}
          />
          {errors.profilePhoto && (
            <span className="error" style={styles2.error}>
              {errors.profilePhoto}
            </span>
          )}
        </div>

        {/* Flexbox for fields */}
        <div style={styles2.formRow}>
          {/* Title Field */}
          <div style={styles2.formColumn}>
            <label>Title</label>
            <select
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              style={styles2.select}
            >
              <option value="">Select Title</option>
              {title.map((name, id, index) => (
                <option key={index} value={id}>
                  {name}
                </option>
              ))}
            </select>
            {errors.title && (
              <span className="error" style={styles2.error}>
                {errors.title}
              </span>
            )}
          </div>
          {/* Gender */}
          <div style={styles2.formColumnLast}>
            <label>Gender</label>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleInputChange}
              style={styles2.select}
            >
              <option value="">Select Gender</option>
              {gender.map((name, id, index) => (
                <option key={index} value={id}>
                  {name}
                </option>
              ))}
            </select>
            {errors.gender && (
              <span className="error" style={styles2.error}>
                {errors.gender}
              </span>
            )}
          </div>
        </div>

        <div style={styles2.formRow}>
          {/* First Name */}
          <div style={styles2.formColumn}>
            <label>First Name</label>
            <input
              placeholder="First Name"
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              style={styles2.input}
            />
            {errors.firstName && (
              <span className="error" style={styles2.error}>
                {errors.firstName}
              </span>
            )}
          </div>

          {/* Last Name */}
          <div style={styles2.formColumnLast}>
            <label>Last Name</label>
            <input
              placeholder="Last Name"
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              style={styles2.input}
            />
            {errors.lastName && (
              <span className="error" style={styles2.error}>
                {errors.lastName}
              </span>
            )}
          </div>
        </div>

        <div style={styles2.formRow}>
          {/* Other Name */}
          <div style={styles2.formColumn}>
            <label>Other Name</label>
            <input
              placeholder="Other Name"
              type="text"
              id="otherName"
              name="otherName"
              value={formData.otherName}
              onChange={handleInputChange}
              style={styles2.input}
            />
            {errors.otherName && (
              <span className="error" style={styles2.error}>
                {errors.otherName}
              </span>
            )}
          </div>

          {/* Phone Number */}
          <div style={styles2.formColumnLast}>
            <label>Phone Number</label>
            <input
              placeholder="Phone Number"
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              style={styles2.input}
            />
            {errors.phoneNumber && (
              <span className="error" style={styles2.error}>
                {errors.phoneNumber}
              </span>
            )}
          </div>
        </div>

        <div style={styles2.formRow}>
          {/* Country */}
          <div style={styles2.formColumn}>
            <label>Country</label>
            <input
              placeholder="Country"
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              style={styles2.input}
            />
            {errors.country && (
              <span className="error" style={styles2.error}>
                {errors.country}
              </span>
            )}
          </div>

          {/* State */}
          <div style={styles2.formColumnLast}>
            <label>State</label>
            <select
              name="state"
              value={formData.state}
              onChange={handleInputChange}
              style={styles2.select}
            >
              <option value="">Select State</option>
              {state.map((name, id, index) => (
                <option key={index} value={id}>
                  {name}
                </option>
              ))}
            </select>
            {errors.state && (
              <span className="error" style={styles2.error}>
                {errors.state}
              </span>
            )}
          </div>
        </div>

        <button type="submit" id="button" name="submit" style={styles2.input} disabled={isLoading}>
        {isLoading ? 'Submiting...' : 'Next'}
        </button>
      </form>
    </div>
      </div>
      {popup.isVisible && (
                <Popup
                    message={popup.message}
                    type={popup.type}
                    onClose={() => setPopup({ ...popup, isVisible: false })}
                    buttonLabel={popup.buttonLabel}
                    buttonRoute={popup.buttonRoute}
                />
            )}
    </div>
  );
};

export default PersonalDetailsMain;
